import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import styles from './404.module.css';

function Page404() {
  return (
    <div className={styles.Page404}>
      <h1 className={styles.MainText}>404</h1>
      <h2>We're puzzled how you got here</h2>
      <div>[Image]</div>
      <Link to="/">
        <img className={styles.homeImage} src={logo} alt='logo' />
      </Link>
    </div>
  );
}

export default Page404;