import styles from './Header.module.css';
import logo from '../../assets/svcclogo.jpg';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className={styles.Header}>
      <div className={styles.ImageContainer}>
        <Link to="/">
          <img src={logo} alt='logo' />
        </Link>
      </div>
      <div className={styles.NavigationLinks}>
        <div>
          <Link to="/">
            <button>
              <h2>Home</h2>
            </button>
          </Link>
        </div>
        <div>
          <Link to="/schools">
            <button>
              <h2>Schools</h2>
            </button>
          </Link>
        </div>
        <div>
          <Link to="/about">
            <button>
              <h2>About</h2>
            </button>
          </Link>
        </div>
        <div>
          <Link to="/contact">
            <button>
              <h2>Contact</h2>
            </button>
          </Link>
        </div>
        <div>
          <Link to="/donate">
            <button>
              <h2>Donate</h2>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;