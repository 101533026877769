import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import './App.css';

import Page404 from './pages/404/404';
import PageSchools from './pages/schools/schools';
import PageAbout from './pages/about/about';
import PageContact from './pages/contact/contact';
import PageDonate from './pages/donate/donate';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import PageHome from './pages/home/home';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <div className="RouteContent">
          <Routes>
            <Route path="/" element={
              <div>
                <Helmet>
                  <title>SV Cubing Club</title>
                  <meta name="description" content="Silicon Valley Cubing Club - Inspiring the next generation of cubers" />
                </Helmet>
                <PageHome />
              </div>
            } />
            <Route path="/schools" element={
              <div>
                <Helmet>
                  <title>Schools - SV Cubing Club</title>
                  <meta name="description" content="Silicon Valley Cubing Club - Schools that we have hosted our club at" />
                </Helmet>
                <PageSchools />
              </div>
            } />
            <Route path="/about" element={
              <div>
                <Helmet>
                  <title>About Us - SV Cubing Club</title>
                  <meta name="description" content="Silicon Valley Cubing Club - Inspiring the next generation" />
                </Helmet>
                <PageAbout />
              </div>
            } />
            <Route path="/contact" element={
              <div>
                <Helmet>
                  <title>Contact - SV Cubing Club</title>
                  <meta name="description" content="Silicon Valley Cubing Club - Contact us via email or provide feedback" />
                </Helmet>
                <PageContact />
              </div>
            } />
            <Route path="/donate" element={
              <div>
                <Helmet>
                  <title>Donate - SV Cubing Club</title>
                  <meta name="description" content="Silicon Valley Cubing Club - Support us with donations to the club" />
                </Helmet>
                <PageDonate />
              </div>
            } />
            <Route path="*" element={
              <div>
                <Helmet>
                  <title>404 Not Found</title>
                  <meta name="description" content="Silicon Valley Cubing | Page Not Found" />
                </Helmet>
                <Page404 />
              </div>
            } />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;