import styles from './donate.module.css';

function PageDonate() {
  return (
    <div className={styles.PageDonate}>
      <h1>Support Us</h1>
      <div className={styles.Content}>
        <p>
          Our goal is simple: to inspire and educate kids in a unique way.
          Your optional support can help us continue making learning a blast and growing a generation of super-smart problem solvers.
          Consider giving a little to our Rubik’s Cube Club and be part of our journey to make education exciting for kids.
          Thanks a million for your generosity!
        </p>
        <p>
          Your donations will be used to buy Rubik’s cubes and supplies for the program.
        </p>
        <p>
          <i>
            Thanks on behalf of Cameron Jiang and Aditya Jha!
          </i>
        </p>
      </div>
    </div>
  );
}

export default PageDonate;