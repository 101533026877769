import styles from './CollapsibleContainer.module.css';
import Collapsible from '../Collapsible/Collapsible';
function CollapsibleContainer({ contents = [] }) {

  return (
    <div className={styles.CollapsibleContainer}>
      {
        contents.map((val, i) => {
          console.log("yes");
          return (
            <Collapsible key={i} text={val} title={"Week " + (i + 1)} />
          )
        })
      }
    </div>
  );
}

export default CollapsibleContainer;