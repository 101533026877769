import { useState, useEffect } from 'react';
import styles from './Stars.module.css';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import ScrollAnimation from 'react-animate-on-scroll';

function Stars({ num }) {

  const [starEl, setStarEl] = useState(<div></div>);

  useEffect(() => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < num) {
        stars.push(
          <ScrollAnimation animatePreScroll={true} animateOnce={true} animateIn="fadeIn" duration={0.5} delay={100 * i} key={i}>
            <FontAwesomeIcon icon={faStar} color='gold' />
          </ScrollAnimation>
        );
      }
      else {
        stars.push(
          <AnimatedOnScroll animationIn="fadeIn" animateOnce={true} animationOut="fadeOut" once={true} key={i}>
            <FontAwesomeIcon icon={faStar} />
          </AnimatedOnScroll>
        );
      }
    }
    setStarEl(stars);
  }, [num]);

  return (
    <div className={styles.Stars}>
      {starEl}
    </div>
  )
}

export default Stars;