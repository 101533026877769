import styles from './ReviewContainer.module.css'
import Rating from '../../components/Rating/Rating';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import { Autoplay } from "swiper/modules";
import { useWindowSize } from "@uidotdev/usehooks";
import { useState, useEffect } from 'react';


function ReviewContainer() {
  const [slidesPerView, setSlidesPerView] = useState(3);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width && windowSize.width < 800){
      setSlidesPerView(1)
    }
    else{
      setSlidesPerView(3);
    }
  }, [windowSize]);

  return (
    <div className={styles.ReviewContainer}>
      <Swiper
        className={styles.SwiperContainer}
        spaceBetween={30}
        loop={true}
        slidesPerView={slidesPerView}
        grabCursor={true}
        modules={[Autoplay]}
        pagination={{
          clickable: true,
        }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        speed={1000}
      >
        <SwiperSlide>
          <div className={styles.RatingContainer}>
            <Rating name={"Ankur Soni"} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.RatingContainer}>
            <Rating name={"Placeholder"} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.RatingContainer}>
            <Rating name={"Placeholder"} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.RatingContainer}>
            <Rating name={"Placeholder"} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.RatingContainer}>
            <Rating name={"Placeholder"} />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default ReviewContainer;