import styles from './Footer.module.css';
import { useState, useEffect } from 'react';
import { useWindowSize } from "@uidotdev/usehooks";

function Footer() {

  const [schoolsEl, setSchoolsEl] = useState(
    <div>
      <p>Carlton Elementary School<br />
        Alta Vista Elementary School</p>
    </div>
  );

  const [contactEl, setContactEl] = useState(
    <div className={styles.ContactInfo}>
      <p>Contact At:<br />
        <a href="mailto:22camerjian@gmail.com">22camerjian@gmail.com</a>
        <br /><a href="mailto:aditya2008jha@gmail.com">aditya2008jha@gmail.com</a></p>
    </div>
  );

  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width && windowSize.width < 700){
      setContactEl(null);
      setSchoolsEl(null);
    }
    else{
      setSchoolsEl(
        <div>
          <p>Carlton Elementary School<br />
            Alta Vista Elementary School</p>
        </div>
      );
      setContactEl(
        <div className={styles.ContactInfo}>
          <p>Contact At:<br />
            <a href="mailto:22camerjian@gmail.com">22camerjian@gmail.com</a>
            <br /><a href="mailto:aditya2008jha@gmail.com">aditya2008jha@gmail.com</a></p>
        </div>
      )
    }
  }, [windowSize]);

  return (
    <div className={styles.Footer}>
      <hr />
      <div className={styles.FooterContent}>
        {schoolsEl}
        <div>
          <p>Cameron Jiang & Aditya Jha</p>
          <div className={styles.CopyrightInfo}>
            <p>Copyright © Silicon Valley Cubing
              <br />All rights reserved</p>
          </div>
        </div>
        {contactEl}
      </div>
    </div>
  );
}

export default Footer;