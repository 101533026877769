import CollapsibleContainer from '../../components/CollapsibleContainer/CollapsibleContainer';
import styles from './schools.module.css';
import { CarltonMeetingLogs, AltaVistaMeetingLogs } from './session_logs.js';
import CarltonCubingPoster from '../../assets/CarltonCubingPoster.png';
import AltaVistaCubingPoster from '../../assets/AltaVistaCubingPoster.png';

function PageSchools() {

  return (
    <div className={styles.PageSchools}>

      <div className={styles.Spacer}></div>

      <div>
        <div className={styles.CarltonPoster}>
          <img src={CarltonCubingPoster} alt={"Carlton Cubing Poster"} />
        </div>

        <div className={styles.rightHalf}>
          <div className={styles.SchoolInfo}>
            <h1>&nbsp;Carlton Elementary</h1>
            <ul>
              <li>Students Taught: 15</li>
              <li>Sessions Hosted: 9</li>
              <li>Hours: 14</li>
            </ul>
            <h2>&nbsp;Meeting Logs</h2>
          </div>
          <div className={styles.SessionLogs}>
            <CollapsibleContainer contents={CarltonMeetingLogs} />
          </div>
        </div>
      </div>

      <div className={styles.floatClear}></div>
      <div className={styles.Spacer}></div>

      <div>
        <div className={styles.AltaVistaPoster}>
          <img src={AltaVistaCubingPoster} alt={"Carlton Cubing Poster"} />
        </div>

        <div className={styles.leftHalf}>
          <div className={styles.SchoolInfo}>
            <h1>Alta Vista Elementary</h1>
            <ul>
              <li>Students Taught: 16</li>
              <li>Sessions Hosted: 6</li>
              <li>Hours: 6</li>
            </ul>
            <h2>&nbsp;Meeting Logs</h2>
          </div>
          <div className={styles.SessionLogs}>
            <CollapsibleContainer contents={AltaVistaMeetingLogs} />
          </div>
        </div>
      </div>

      <div className={styles.floatClear}></div>

    </div>
  );
}

export default PageSchools;