import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import styles from './home.module.css';
import ReactVisibilitySensor from "react-visibility-sensor";
import Button from '../../components/Button/Button';
import ReviewContainer from '../../components/ReviewContainer/ReviewContainer';
import ImageCube from '../../components/ImageCube/ImageCube';
// import banner from '../../assets/CubingClubBanner.jpg';

function PageHome() {
  const [participants, setParticipants] = useState(0);
  const [hours, setHours] = useState(0);
  const [schools, setSchools] = useState(0);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    if (!isCounting) return;

    const participantInterval = setInterval(() => {
      setParticipants((participants) => Math.min(participants + 1, 35));
    }, 35);
    const hoursInterval = setInterval(() => {
      setHours((hours) => Math.min(hours + 1, 35));
    }, 50);
    const schoolsInterval = setInterval(() => {
      setSchools((schools) => Math.min(schools + 1, 2));
    }, 500);

    return () => {
      clearInterval(participantInterval);
      clearInterval(hoursInterval);
      clearInterval(schoolsInterval);
    };
  }, [isCounting]);

  return (
    <div className={styles.PageHome}>
      <ImageCube />
      {/* <img src={banner} className={styles.banner}></img> */}
      <ReactVisibilitySensor onChange={(isVisible) => { setIsCounting(isVisible) }}>
        <div className={styles.Stats}>
          <div>
            <h1>{participants}</h1>
            <p><b>Participants</b></p>
          </div>
          <div>
            <h1>{hours}+</h1>
            <p><b>Hours of Teaching</b></p>
          </div>
          <div>
            <h1>{schools}</h1>
            <p><b>Schools</b></p>
          </div>
        </div>
      </ReactVisibilitySensor>
      <h1 className={styles.textHeader}>
        Our Vision
      </h1>
      <p className={styles.textContainer}>
        As young educators and enthusiasts of the Rubik’s Cube, our goal is to foster an environment that all
        students can enjoy and to cultivate deeper connections between students in the community through this program.
        Through our inter-school program, we aspire to empower young minds with problem solving and logical reasoning skills,
        making every twist and turn a memorable learning experience.
      </p>
      <Link to="/about" className={styles.LearnMoreBtn}>
        <Button text={"Learn More"} />
      </Link>
      <div className={styles.spacer}></div>
      <h1 className={styles.textHeader}>
        What Parents Say!
      </h1>
      <div className={styles.ReviewContainer}>
        <ReviewContainer />
      </div>
    </div >
  );
}

export default PageHome;