import styles from './contact.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function PageContact() {
  return (
    <div className={styles.PageContact}>
      <h1 className={styles.EmailDescription}>Our Emails Are:</h1>
      <div className={styles.ContactEmails}>
        <p>
          <FontAwesomeIcon icon={faEnvelope} />
          &nbsp;
          <a href="mailto:22camerjian@gmail.com">22camerjian@gmail.com</a>
        </p>
        <p>
          <FontAwesomeIcon icon={faEnvelope} />
          &nbsp;
          <a href="mailto:aditya2008jha@gmail.com">aditya2008jha@gmail.com</a>
        </p>
        <h1 className={styles.Header}>Feel free to reach out if you have any questions!</h1>
      </div>
      <div className={styles.spacer} />

      <div clasName={styles.FeedbackContainer}>
        <h1 className={styles.FeedbackHeader}>Have Feedback?</h1>
        <h2 className={styles.FormContent} >Cubing Club Feedback:&nbsp;
          <a
            className={styles.FeedbackLink}
            href="https://docs.google.com/forms/d/e/1FAIpQLSfnbeGLfXoNGTRZ3RUjf96wSM8ADvsnWAXN48sfo0rX4tNe2g/viewform">
            Google Form
          </a>
        </h2>
        <div className={styles.FormEmbed}>
          <iframe
            title="Feedback Form Embed"
            src="https://docs.google.com/forms/d/e/1FAIpQLSfnbeGLfXoNGTRZ3RUjf96wSM8ADvsnWAXN48sfo0rX4tNe2g/viewform?embedded=true"
            width="700"
            height="520"
            frameborder="0"
            marginheight="0"
            marginwidth="0">
            Loading…
          </iframe>
        </div>
      </div>
    </div>
  );
}

export default PageContact;