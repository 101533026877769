const CarltonMeetingLogs = [
  `Our first week at Carlton went well. Today, we were able to get to no most of the students and introduce the overall process we will be going through to solve the cube. Some eager students even started the first few steps and are already making good progress.
  Many people already have a Rubik’s cube at home and some even know how to solve a few steps. We look forward to teaching kids of all levels and enabling all of them to solve the cube.`,
  `The students were mostly able to recall the process of solving the cross even though a week had passed.
  Cameron helped teach the first layer while Aditya assisted those who forgot parts of last week’s session.
  By the end of the day almost all the students were caught up and on pace.`,
  `Today ended being mostly review.
  Students worked on completing the first layer while the more advanced kids moved on the second layer.
  It seems that a good majority of the students have a solid understanding of the first layer now.`,
  `Today, many students learned how to solve the first two layers and some even moved on to one of the last steps.
  Most students can comfortably solve 2/3 of the Rubik’s cube and are eager to learn more. Based on our progress so far, many students should expect to finish by week 7 or 8.
  We also introduced a bunch of challenges and activities that students can complete at the end of the meetings to earn fun prizes.`,
  `Many students are really close to being able to solve the Rubik’s cube.
  Today we reviewed all the steps and made sure a lot of people were ready to move on to the final step.
  The students are getting really exited since they are so close to solving the cube!`,
  `A student attended the meeting after watching a tutorial about the final step, being able to solve the cube in under a minute.
  Four students were able to completely solve the Rubik’s cube.
  The remaining students are on pace to learning the entire process — even those who joined halfway through are caught up for the most part`,
  `Today’s meeting was a great success. Although we had a smaller attendance than usual, all our students were able to completely solve the cube! Hopefully by the next meeting the majority of our participants will have completed their journey of solving their first Rubik’s Cube.`,
  `Today was the second to last meeting at Carlton. We helped anyone with questions and reviewed the solving methods. The consistent members are able to solve the cube now and are enjoying it!`,
  `Today was the last meeting at Carlton. Since everyone in attendance today already knew how to solve the Rubik’s cube, we ended with letting them play around with the cool puzzles we had. It was a great experience and hopefully, we can come back soon!`
];

const AltaVistaMeetingLogs = [
  `At Alta Vista, we were met by new students eager to learn how to solve the cube. We introduced each step involved and began teaching the first 2 steps. After an hour, many of the students are able to solve the cross and all are able to create the daisy.`,
  `We taught students to solve the first face while reviewing the cross. Half of the students learned the first layer and the others were able to grasp the algorithm needed.`,
  `Today, we would like to welcome our new instructor, Rentaro. We continued teaching the first layer to some students and pushed ahead and taught the second layer to the more advanced learners.`,
  `We reviewed all the steps up to the second layer, and many students were able to firmly grasp the techniques. Some of the students who were behind schedule caught up as well. We also began teaching the yellow cross to advanced learners.`,
  `There was a surprising yet delightful incident that occurred during this Cubing Club session. At the start, we proceeded to teach the next steps to the students, but found that they learned quickly. By the end of the class six of the students were able to completely solve the cube!`,
  `Many of our students now know how to solve the Rubik's Cube. Our main focus for the rest of the meetings is to practice solving the cube and introduce them to some new fun puzzles for them to try out.`,
  `As of meeting #7, most of the students can now completely solve the Rubik's cube. Now, students are having fun racing their peers and seeing who can solve some of the other twisty puzzles we have.`,
  `For our final meeting, we planned a fun event. Those who chose to could participate in a competition where each student would get 3 timed solves. We were surprised to find out that most students were average around 1-2 minutes! Those who didn't participate in the competition helped us make force cubes. Overall, the students had a lot of fun in the last meeting and we look forward to coming back to Alta Vista in the future`,
]

export {
  CarltonMeetingLogs,
  AltaVistaMeetingLogs,
}