import styles from './about.module.css';
import AboutUs from '../../assets/AboutUs.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function PageAbout() {
  return (

    <div className={styles.PageAbout}>
      <img src={AboutUs} className={styles.Image} alt="About Us" />
      <div className={styles.AboutText}>
        <h1 className={styles.Header}>How it all started</h1>
        <div className={styles.TextContent}>
          <p>
            As two avid enthusiasts of solving the Rubik’s cube and educating, we decided to combine our passions into one program in
            which we could teach elementary students of all ages various cognitive skills through our favorite puzzle.
            The result of this effort is a multi-school organization that we are extremely proud of: Silicon Valley Cubing Club.
            We hope that if your child has the chance to attend,
            they will get to experience the same joy we did when we solved our first Rubik’s cube.
          </p>
        </div>
      </div>
      <div className={styles.spacer}></div>
      <div className={styles.floatClear}></div>

      <h1 className={styles.HeaderLarge}>Our Story</h1>
      <p className={styles.StoryIntro}>
        <b>We</b> are Aditya Jha and Cameron Jiang, two students at Leigh High School. Here is a bit more about us:
      </p>
      <table className={styles.FounderTable}>
        <tbody>
          <tr className={styles.FounderNames}>
            <td>Aditya Jha</td>
            <td>Cameron Jiang</td>
          </tr>
          <tr>
            <td>
              <FontAwesomeIcon className={styles.FounderImage} icon={faImage} size='5x' />
            </td>
            <td>
              <FontAwesomeIcon className={styles.FounderImage} icon={faImage} size='5x' />
            </td>
          </tr>
          <tr>
            <td>
              Hi! I’m Aditya Jha. I first learned to solve the Rubik’s Cube in 2019 and have been fascinated by it ever since.
              The initial challenge of solving the puzzle for the first time and the continued satisfaction I had from improving my times has captivated me for years.
              I wanted to share my love of the puzzle not just with my friends and family, but with a broader community and that’s what motivated me to co-found this program.
              I think that cubing is one of the greatest hobbies of all time and I want more people to be able to enjoy it the same way my friends and I do!
            </td>
            <td>
              I am Cameron Jiang and solving the Rubik’s Cube, or cubing, is a favorite hobby of mine. For me, it all started during the winter of 2015.
              I distinctly recall my dad’s sketches and arrows labeling the steps needed to solve a specific pattern and
              the old wooden cube with tattered stickers that would mysteriously solve itself before my dad left for work in the morning.
              My first solve of the Rubik’s Cubewas one of the most delightful moments in my childhood,
              and even the journey of anger and frustration leading up to that point left a lasting impression.
              Co-founding this program is a means for me to let others experience the magic of cubing.
            </td>
          </tr>
        </tbody>
      </table>

      <div className={styles.spacer}></div>

      <div>
        <h1 className={styles.VolunteerHeader}>
          Volunteers
        </h1>
        <p>Huge thanks to our volunteers who have greatly helped the club:</p>
        <table className={styles.VolunteerTable}>
          <tbody>
            <tr>
              <td className={styles.VolunteerName}>
                <h1>Rentaro N</h1>
                <FontAwesomeIcon icon={faImage} size='5x' />
              </td>
              <td className={styles.VolunteerStats}>
                <p>Volunteer since May 2024</p>
                <p>Student at Leigh High School</p>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} />
                  &nbsp;
                  <a href="mailto:rentaro.noguchi@gmail.com">rentaro.noguchi@gmail.com</a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PageAbout;