import styles from './ImageCube.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import { EffectCube, Autoplay } from "swiper/modules";

import CarltonSchoolImg from '../../assets/Gallery/CarltonSchool.jpg';
import AltaVistaSchoolImg from '../../assets/Gallery/AltaVistaSchool.jpeg';
import CubingImage1 from '../../assets/Gallery/CubingImage1.jpeg';
import CubingImage2 from '../../assets/Gallery/CubingImage2.jpeg';
import AltaVistaImage1 from '../../assets/Gallery/AltaVistaImage1-Square.jpg';
import AltaVistaImage2 from '../../assets/Gallery/AltaVistaImage2-Square.jpg';

function ImageCube() {
  return (
    <div className={styles.ImageCube}>
      <Swiper
        effect={'cube'}
        grabCursor={true}
        cubeEffect={{
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        speed={1000}
        modules={[EffectCube, Autoplay]}
        style={{ width: 'min(75vh, 75vw)' }}
      >
        <SwiperSlide style={{ width: 'min(75vh, 75vw)' }}>
          <img src={AltaVistaImage1} alt="Holding Cubes with Alta Vista Extended Care Director" />
        </SwiperSlide>
        <SwiperSlide style={{ width: 'min(75vh, 75vw)' }}>
          <img src={AltaVistaSchoolImg} alt="Alta Vista School Entrance" />
        </SwiperSlide>
        <SwiperSlide style={{ width: 'min(75vh, 75vw)' }}>
          <img src={CubingImage1} alt="Cubing At Alta Vista (1)" />
        </SwiperSlide>
        <SwiperSlide style={{ width: 'min(75vh, 75vw)' }}>
          <img src={AltaVistaImage2} alt="Holding Cubes with Alta Vista Extended Care" />
        </SwiperSlide>
        <SwiperSlide style={{ width: 'min(75vh, 75vw)', aspectRatio: '1 / 1' }}>
          <img src={CubingImage2} alt="Cubing At Alta Vista (2)" />
        </SwiperSlide>
        <SwiperSlide style={{ width: 'min(75vh, 75vw)' }}>
          <img src={CarltonSchoolImg} alt="Carlton School Entrance" />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default ImageCube;