import styles from './Rating.module.css';
import ScrollAnimation from'react-animate-on-scroll';
import Stars from '../../components/Stars/Stars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

function Rating({ name, comment = "" }) {
  return (
    <div className={styles.Rating}>
      <ScrollAnimation animateIn="fadeIn">
        <div className={styles.ProfileImage}>
        <FontAwesomeIcon icon={faUser} />
        </div>
        <div className={styles.Content}>
          <div className={styles.StarContainer}>
            <Stars num={5} />
          </div>
          <div>
            {comment}
          </div>
          <div className={styles.ParentName}>
            {name}
          </div>
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default Rating;