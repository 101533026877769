import styles from './Collapsible.module.css';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";

function Collapsible({ text, title }) {

  const [contentEl, setContentEl] = useState(null);

  function openContent() {
    if (!contentEl) {
      setContentEl(
        <div className={styles.Content}>
          <p>{text}</p>
        </div>
      );
    }
    else {
      setContentEl(null);
    }
  }

  return (
    <div className={styles.Collapsible}>
      <div>
        <button onClick={openContent}>
          {contentEl ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretRight} />}
          &nbsp;{title}
        </button>
      </div>
      {contentEl}
    </div>
  );
}

export default Collapsible;